<template>
    <div class="cont-table-search-cand">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>


        <div class="row cont_ricerca_candidati">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <a href="" target="_blank" id="lnkReport"></a>

                        <div class="table-responsive" style="">

                            <table id="myTable">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>


                                        <th class='all'>N.</th>

                                        <th class='all' style="text-align: center;">
                                            <div class="clColGestito">Gestito</div>
                                        </th>

                                        <th class='desktop'>WIP</th>


                                        <th class='desktop'>Inserito</th>
                                        <th class='desktop'>Aggiornato</th>
                                        <th class='all'>Cognome</th>
                                        <th class='desktop'>Nome</th>

                                        <th class='desktop'>Esperienza</th>

                                        <th class='all'>E-Mail</th>
                                        <th class='desktop'>Telefono</th>
                                        <th class='desktop'>Skill</th>
                                        <th class='desktop'>Stato</th>



                                        <th class='desktop'>Data Nascita</th>
                                        <th class='desktop'>Reg. Res.</th>
                                        <th class='desktop'>Portale</th>
                                        <th class='desktop'>Cliente</th>
                                        <th class='desktop'>Annuncio</th>
                                        <th class='desktop'>Tipo Contratto</th>
                                        <th class='desktop'>Preavviso</th>
                                        <th class='desktop'>RAL</th>
                                        <th class='desktop'>Feedback</th>
                                        <th class='desktop'>Note</th>
                                        <th class='desktop'>
                                            <div class="clColCvPers">CV Pers.</div>
                                        </th>
                                        <th class='desktop'>
                                            <div class="clColCvAzi">CV Azi.</div>
                                        </th>


                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="25">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetChangeStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetChangeStatus = !sheetChangeStatus">

                        <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition"
                            style="margin-right:20px!important" />


                    </v-btn>
                    <div class="py-3">
                        Cambia stato in
                    </div>
                    <v-list-item v-for="tile in allStatus" :key="tile.value" @click="changeStatus(tile.value)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.description
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetFilterStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetFilterStatus = !sheetFilterStatus">
                        <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition"
                            style="margin-right:20px!important" />
                    </v-btn>
                    <div class="py-3">
                        Filtro stato
                    </div>
                    <v-list-item v-for="tile in allFilterStatus" :key="tile.value"
                        @click="changeFilterStatus(tile.value)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.description
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="contpopuphistory" style="display:none">

            <template>
                <modal name="popupHistory" :clickToClose="false" :width="350" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Storico Clienti

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">

                            <table style="margin-left:11px">
                                <thead>
                                    <tr>
                                        <th style="padding:5px 5px 5px 5px;width:95px">Nome</th>
                                        <th style="padding:5px 5px 5px 5px;width:95px">Referente</th>
                                        <th style="padding:5px 5px 5px 5px">Data</th>
                                        <th style="padding:5px 5px 5px 5px">Stato</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="cust in customers_history" :key="cust.id">
                                        <td style="padding:5px 5px 5px 5px;width:95px">{{ cust.fld_name }}</td>
                                        <td style="padding:5px 5px 5px 5px;vertical-align:top;width:95px">
                                            {{ cust.referente }}</td>
                                        <td style="padding:5px 5px 5px 5px;vertical-align:top">{{ cust.data }}</td>
                                        <td style="padding:5px 5px 5px 5px;vertical-align:top">{{ cust.description }}
                                        </td>
                                    </tr>
                                </tbody>



                            </table>





                        </v-col>


                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-history" contain src="@/assets/btn_cancel3.png"
                                    max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;transform:translateY(218px)"
                                    transition="scale-transition" title="Annulla" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/2recr_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label=""
                                style="font-size:11px;color:black;font-weight:bold">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/delete-2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Elimina filtro" @click="btnDeleteSearch" />

                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupmail" style="display:none">

            <template>
                <modal name="popupMail" :clickToClose="false" :width="300" :height="500">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;font-size: 11px;color:black">

                            Anteprima Mail

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:5px!important">

                        <v-col cols="12" md="12">

                            <textarea id="sec_body" name="sec_body" class="sec_body" autofocus
                                style="width:270px!important;height:400px!important"></textarea>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-mail" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:20px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-view-ads" contain src="@/assets/ads_mail_2recruit.png"
                                    max-width="42" max-height="42"
                                    style="cursor:pointer;margin-right:20px;margin-top:-6px"
                                    transition="scale-transition" title="Annunci Selezionati" />

                                <v-img alt="" class="btn-confirm-mail" contain src="@/assets/btn_confirm3_2recruit.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupads" style="display:none">

            <template>
                <modal name="popupAds" :clickToClose="false" :width="333" :height="500">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;font-size:11px;color:black;">

                            Annunci

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="height:358px!important;width:100%!important;overflow-y:auto!important">

                                <table style="width:100%">
                                    <thead>
                                        <tr>
                                            <th style="width:10%;padding-left:7px">&nbsp;</th>
                                            <th style="width:23%;font-size:11p;color:black;font-weight:bold;">Azienda
                                            </th>
                                            <th style="width:22%;font-size:11p;color:black;font-weight:bold;">Codice
                                            </th>
                                            <th style="width:45%;font-size:11p;color:black;font-weight:bold;">Annuncio
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="v_ads in listAds" :key="v_ads.number_ads">
                                            <td style="padding-top:5px;padding-left:7px;vertical-align:top!important">
                                                <input type='checkbox' class="val_status_ads"
                                                    style="width:10px!important" :value="v_ads.number_ads" />
                                            </td>

                                            <td
                                                style="padding-top:5px;vertical-align:top!important;font-size:11p;color:black;font-weight:bold">
                                                {{ v_ads.fld_name
                                                }}</td>


                                            <td
                                                style="padding-top:5px;vertical-align:top!important;font-size:11p;color:black;font-weight:bold">
                                                {{ v_ads.number_ads
                                                }}</td>
                                            <td
                                                style="padding-top:5px;vertical-align:top!important;font-size:11p;color:black;font-weight:bold">
                                                {{ v_ads.title }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>


                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-ads" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:20px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-confirm-ads" contain src="@/assets/btn_confirm3_2recruit.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopupinfocandidato" style="display:none">

            <template>
                <modal name="popupInfoCandidato" :clickToClose="false" :width="350" :height="409">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/info_2recruit.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">

                            <label style="font-size:15px;margin-left:10px;color:black;font-weight:bold;">Nome</label>
                            <div style="font-weight:bold;font-size: 19px;margin-left:10px;color:black">{{ nomeCandidato
                                }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">

                            <label style="font-size:15px;margin-left:10px;color:black;font-weight:bold">Cognome</label>
                            <div style="font-weight:bold;font-size:19px;margin-left:10px;color:black">{{
                                cognomeCandidato }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">

                            <label style="font-size: 15px;margin-left:10px;color:black;font-weight:bold">E-Mail</label>
                            <div style="font-weight:bold;font-size: 19px;margin-left:10px;color:black"><a
                                    class="lnkEMail" :href="hrefMailCandidato">{{ emailCandidato }}</a>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>
                        <v-col cols="12" md="12">

                            <label
                                style="font-size: 15px;margin-left:10px;color:black;font-weight:bold">Cellulare</label>
                            <div style="font-weight:bold;font-size: 19px;margin-left:10px;color:black"><a
                                    class="lnkPhone" :href="hrefCellCandidato">{{
                                        cellulareCandidato
                                    }}</a>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-history" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" title="Annulla" @click="btnCancelInfoCandidato" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupreport" style="display:none">

            <template>
                <modal name="popupReport" :clickToClose="false" :width="300" :height="415">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;margin-bottom:7px!important">

                            Inserito

                        </v-col>

                    </v-row>



                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="inseritoDal" label="Dal" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="inseritoDal" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="inseritoAl" label="Al" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="inseritoAl" no-title @input="showPicker2 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12"
                            style="font-weight:bold;margin-left:10px;margin-top:0px!important;padding-top:0px!important;margin-bottom:7px!important">

                            Aggiornato

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="aggiornatoDal" label="Dal" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="aggiornatoDal" no-title @input="showPicker3 = false"
                                    locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="aggiornatoAl" label="Al" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="aggiornatoAl" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px!important;padding-top:0px!important;margin-left:6px!important">

                        <v-col cols="12" md="12"
                            style="margin-left:6px!important;margin-top:0px!important;padding-top:0px!important">



                            <ion-item>

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Stato</ion-label>

                                <ion-select id="idStato" name="idStato" class='idStato' cancel-Text="Chiudi"
                                    done-Text="" :value="idStato" interface="action-sheet">

                                    <ion-select-option v-for="item in allFilterStatus" :key="item.value"
                                        :value='item.value'> {{
                                            item.description }}</ion-select-option>

                                </ion-select>

                            </ion-item>


                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-to" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelReport" />

                                <v-img alt="" class="btn-reset-date-to" contain src="@/assets/reset.png" max-width="21"
                                    max-height="21" style="cursor:pointer;margin-right:10px;margin-top:4px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetReport" />

                                <v-img alt="" class="btn-confirm-date-to" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="30" max-height="30"
                                    transition="scale-transition" style="cursor:pointer" title="Conferma"
                                    @click="btnConfirmReport" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contregioni">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetRegioni" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Regioni
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetRegioni = !sheetRegioni">
                                    <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                                        max-width="30" max-height="30" transition="scale-transition"
                                        style="margin-right:20px!important" />
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredRegioni" :key="item.idregione"
                                        @click="manageClickRegioni(item)">

                                        <v-img alt="" src="@/assets/2recr_regioni.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.nome }}
                                        </v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>

                            <div style="min-height:170px;height:170px">&nbsp;</div>



                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetFilterGestito">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetFilterGestito = !sheetFilterGestito">
                        <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition"
                            style="margin-right:20px!important" />
                    </v-btn>
                    <div class="py-3">
                        Filtro gestito
                    </div>
                    <v-list-item v-for="tile in allFilterGestiti" :key="tile.value"
                        @click="changeFilterGestito(tile.value)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.description
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="contpopupviewfilepdf" style="display:none">

            <template>
                <modal name="popupViewFilePdf" id="pViewFilePdf" :clickToClose="false" :width="1651" :height="600">



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Vedi CV

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;">

                            <div style="width: 94%;height: 50vh;overflow-y: auto;overflow-x: auto;">

                                <div id="container-view-pdf"></div>

                            </div>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFilePdf" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupviewfile" style="display:none">

            <template>
                <modal name="popupViewFile" id="pViewFile" :clickToClose="false" :width="1651" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            View CV

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;">

                            <iframe id="iframe-pdf" style="width: 97%;
    height: 50vh;"></iframe>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFile" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupchooseviewfile" style="display:none">

            <template>
                <modal name="popupChooseViewFile" :clickToClose="false" :width="262" :height="233">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">


                            Vedi CV

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-radio-group v-model="chooseViewFile">
                                <v-radio label="Personale" value="CV_PERSONALE"></v-radio>
                                <v-radio label="Aziendale" value="CV_AZIENDALE"></v-radio>
                            </v-radio-group>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-choose-file" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelChooseFile" />

                                <v-img alt="" class="btn-confirm-choose-file" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmChooseFile" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupviewfiledoccvpers" style="display:none">

            <template>
                <modal name="popupViewFileDocCvPers" id="pViewFile" :clickToClose="false" :width="1651" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            View CV

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;">

                            <iframe id="iframe-pdf" style="width: 97%;
    height: 50vh;"></iframe>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFileDocCvPers" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupviewfiledoccvpersmobile" style="display:none">

            <template>
                <modal name="popupViewFileDocCvPersMobile" :clickToClose="false" :width="360" :height="659">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            View CV

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <iframe id="iframe-pdf" style="margin-left: 10px;
width: 94%;
height: 57vh;"></iframe>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFileDcoCvPersMobile" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupiterinterrotto" style="display:none">

            <template>
                <modal name="popupIterInterrotto" :clickToClose="false" :width="312" :height="254">


                    <v-row
                        style="margin-top: 0px!important;padding-top: 0px!important;margin-bottom: 0px!important;padding-bottom: 0px!important;">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;margin-bottom: 0px!important;">

                            Motivazione Iter Interrotto

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-top: 0px!important;padding-top: 0px!important;margin-bottom: 0px!important;padding-bottom: 0px!important;">

                        <v-col cols="12" md="12"
                            style="margin-top: -27px!important;padding-top: 0px!important;margin-bottom: 0px!important;padding-bottom: 0px!important;">

                            <v-textarea v-model="motIterInterrotto" label="" id="iter_interrotto">
                            </v-textarea>


                        </v-col>

                    </v-row>


                    <v-row
                        style="margin-top: 0px!important;padding-top: 0px!important;margin-bottom: 0px!important;padding-bottom: 0px!important;">

                        <v-col cols="12" md="12"
                            style="margin-top: 0px!important;padding-top: 0px!important;margin-bottom: 0px!important;padding-bottom: 0px!important;">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelIterInterrotto" />

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnConfirmIterInterrotto" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </div>
</template>

<script>
import apicandidate from "../utils/candidate/apicandidate";

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';


import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/



        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        var pointerVue = this;

        window.curStatus = window.$cookies.get("ricerca_candidati_filtro_stato");
        window.curSrcText = window.$cookies.get("ricerca_candidati_filtro_testo");
        window.idRegione = window.$cookies.get("ricerca_candidati_filtro_id_regione");
        window.curGestito = window.$cookies.get("ricerca_candidati_filtro_gestiti");

        window.portali = "";
        window.regioni = "";
        window.contratti = "";
        window.studi = "";
        window.giornatatipo = "";
        window.province = "";
        window.comuni = "";

        window.dateStartFilter = "";
        window.dateEndFilter = "";

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );


        $(window).resize(function () {

            pointerVue.centerPopupViewFile();

            pointerVue.centerPopupViewFilePdf();


        });


        $(document).ready(function () {

            $('body').on('click', '.selregioni', function () {

                console.log(("EVENTO CLICK SELREGIONI"));

                pointerVue.sheetRegioni = true;

            });


            $('body').on('click', '.selpdfreport', function (event) {
                console.log(event);



                console.log("DEF DATE START: ", window.dateStartFilter);
                console.log("DEF DATE END: ", window.dateEndFilter);

                pointerVue.inseritoAl = window.dateEndFilter;
                pointerVue.inseritoDal = window.dateStartFilter;
                pointerVue.aggiornatoAl = window.dateEndFilter;
                pointerVue.aggiornatoDal = window.dateStartFilter;
                pointerVue.idStato = null;

                $(".contpopupreport").show();

                pointerVue.$modal.show('popupReport');



            });


            $('body').on('ionChange', '.idStato', function (event) {
                console.log(event);

                pointerVue.idStato = this.value;

            });


            $('body').on('click', '.down_cv_azi', function () {

                console.log(("EVENTO CLICK DOWN_CV_AZI"));

                var data_id = $(this).attr("data-id");

                console.log(("DATA-ID: ", data_id));


                var url_call = pointerVue.getUrlDomain("TwoRecruitCvRicDownloadApi") + "?f=" + data_id;

                console.log("URL TO CALL: ", url_call);


                window.open(url_call, "_blank");


            });


            $('body').on('click', '.btn-view-ads', function () {

                console.log(("EVENTO CLICK BTN_VIEW_ADS"));

                tinymce.remove();

                pointerVue.$modal.hide('popupMail');

                pointerVue.returnToAds();

            });

            $('body').on('click', '.btn-cancel-ads', function () {

                console.log(("EVENTO CLICK BTN_CANCEL_ADS"));

                pointerVue.$modal.hide('popupAds');

            });


            $('body').on('click', '.btn-confirm-ads', function () {

                console.log(("EVENTO CLICK BTN_CONFIRM_ADS"));

                var tot = 0;
                var ids = "";

                $(".val_status_ads").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {

                        tot = tot + 1;

                        if (ids != "") {
                            ids = ids + ",";
                        }
                        ids = ids + $(this).val();
                    }

                });

                console.log("TOT: ", tot);
                console.log("ANN SEL: ", ids);

                if (tot > 0) {

                    pointerVue.idAdsSel = ids;

                    pointerVue.$modal.hide('popupAds');

                    pointerVue.loadPreview();

                }
                else {

                    pointerVue.$swal({
                        icon: "error",
                        text: "Selezionare degli annunci",
                        showConfirmButton: false,
                        timer: 2000
                    });

                }



            });



            $('body').on('click', '.btn-confirm-mail', function () {

                console.log(("EVENTO CLICK BTN_MAIL"));

                pointerVue.sendMail();

            });


            $('body').on('click', '.btn-cancel-mail', function () {

                console.log(("EVENTO CLICK BTN_CANCEL_MAIL"));

                tinymce.remove();

                pointerVue.$modal.hide('popupMail');

            });



            $('body').on('click', '.selaggiorna', function () {

                console.log(("EVENTO CLICK SELAGGIORNA"));

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                window.table.ajax.reload();

                pointerVue.hideShowButtonsSelRow();

            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });


            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });

            $('body').on('click', '.btn-cancel-history', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupHistory');

            });





        });

        setTimeout(() => {
            this.initFilter();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            var ids = "";

            var that = this;

            switch (data) {

                case "btn_delete":


                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editRicercaCandidati/0"
                    });
                    break;

                case "btn_update_status":

                    this.sheetChangeStatus = true;

                    break;


                case "btn_history_cust":

                    ids = "";


                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");



                        }

                    });


                    console.log("IDS SEL:", ids);

                    window.$cookies.set("sel_id_cand_hist_cust", ids, "9y");

                    router.push({
                        path: "/storicoClientiProposti"
                    });


                    break;


                case "btn_edit":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editRicercaCandidati/" + ids
                    });




                    break;

                case "btn_back":
                    router.push({
                        path: "/filterusers"
                    });
                    break;

                case "btn_send_invite":


                    this.checkPrivacy();

                    break;

                case "btn_info":

                    this.infoCandidato();

                    break;

                case "btn_crea_cv_azi":

                    this.createCVCompany();

                    break;

                case "btn_down_cv_pers":

                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;


                    setTimeout(() => {

                        var url_cv_pers = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                url_cv_pers = $(this).attr("url-cv-pers");

                            }

                        });

                        console.log("URL: ", url_cv_pers);

                        var a = document.createElement('A');
                        a.href = url_cv_pers;
                        a.target = "_blank";
                        a.download = url_cv_pers.substr(url_cv_pers.lastIndexOf('/') + 1);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);


                        setTimeout(() => {

                            that.showSpinner = false;
                            that.$root.$children[0].showProgress = false;


                        }, 1000);


                    }, 500);

                    break;

                case "btn_down_cv_azi":


                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;


                    setTimeout(() => {

                        var url_cv_azi = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                url_cv_azi = $(this).attr("url-cv-azi");

                            }

                        });

                        console.log("URL: ", url_cv_azi);

                        var url_call = that.getUrlDomain("TwoRecruitCvRicDownloadApi") + "?f=" + url_cv_azi;

                        console.log("URL TO CALL: ", url_call);

                        window.open(url_call, "_blank");

                        setTimeout(() => {

                            that.showSpinner = false;
                            that.$root.$children[0].showProgress = false;


                        }, 1000);


                    }, 500);



                    break;




                case "btn_history":

                    ids = "";

                    var has_cust = "";


                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");

                            has_cust = $(this).attr("has-cust");

                        }

                    });


                    console.log("IDS SEL:", ids);

                    window.$cookies.set("sel_id_cand_hist", ids, "9y");

                    window.$cookies.set("sel_id_cand_hist_cust_has_cust", has_cust, "9y");


                    router.push({
                        path: "/storicoRicercaCondidati"
                    });


                    break;


                case "btn_view_file":

                    this.viewFile();

                    break;


                // case "btn_view_cv_pers":

                //     this.viewPdfFile();

                //     break;

                // case "btn_view_cv_azi":

                //     this.viewFileDocDocx();

                //     break;


                default:
                    break;

            }
        });



        this.setupButtonsGrid();


        window.start_ = 0;

        pointerVue.setupGrid();

        this.hideShowButtons(false);


        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 700);




    },


    computed: {

        filteredRegioni() {
            return _.orderBy(this.regioni.filter(item => {
                if (!this.search) return this.regioni;
                return (item.nome.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


    },






    data: () => ({


        motIterInterrotto: "",

        chooseViewFile: "",


        nomeFile: "",


        pdfUrl: "",


        hrefCellCandidato: "",
        targetCellCandidato: "",


        hrefMailCandidato: "",


        allFilterGestiti: [
            {
                value: "",
                description: "Tutti"
            },
            {
                value: "N",
                description: "Non Gestiti"
            },
            {
                value: "S",
                description: "Gestiti"
            }
        ],


        sheetFilterGestito: false,

        sheetRegioni: false,

        regioni: [],

        search: "",


        idStato: "",
        inseritoAl: null,
        inseritoDal: null,

        aggiornatoAl: null,
        aggiornatoDal: null,

        nomeCandidato: "",
        cognomeCandidato: "",
        emailCandidato: "",
        cellulareCandidato: "",

        idAdsSel: "",
        mailToAds: "",

        listAds: [],


        textSearch: "",

        row_selected: null,


        sheetChangeStatus: false,
        sheetFilterStatus: false,

        customers_history: [],


        allStatus: [],
        allFilterStatus: [],

        showPicker: false,
        showPicker2: false,
        showPicker3: false,
        showPicker4: false,


        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {




        btnConfirmSearch: function () {

            window.curStatus = "-1";
            window.idRegione = "-1";
            window.curGestito = ""

            window.$cookies.set("ricerca_candidati_filtro_testo", "", "9y");

            window.$cookies.set("ricerca_candidati_filtro_stato", "-1", "9y");

            window.$cookies.set("ricerca_candidati_filtro_id_regione", "-1", "9y");

            window.$cookies.set("ricerca_candidati_filtro_gestiti", "", "9y");

            window.curSrcText = this.textSearch;

            window.$cookies.set("ricerca_candidati_filtro_testo", this.textSearch, "9y");

            this.$modal.hide('popupSearch');

            window.table.ajax.reload();

        },


        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnCancelIterInterrotto: function () {

            this.$modal.hide('popupIterInterrotto');

        },

        btnConfirmIterInterrotto: async function () {

            var errore = "";
            var that = this;

            if (that.motIterInterrotto == "") {
                errore = "Specificare la motivazione";
            }


            if (errore == "") {

                that.$modal.hide('popupIterInterrotto');

                var v_token = window.$cookies.get("token");

                var ids = "";

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {

                        if (ids != "") {
                            ids = ids + ",";
                        }
                        ids = ids + $(this).attr("data-id");
                    }

                });

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;


                var response = await apicandidate.updateMultiStatus(
                    v_token,
                    ids,
                    "Iter interrotto",
                    that.motIterInterrotto
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateMultiStatus", res);

                    that.$swal({
                        icon: "success",
                        text: "Stati aggiornati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();


                        window.table.ajax.reload();


                        that.hideShowButtons(false);


                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );



            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }




        },




        centerPopupViewFilePdf: function () {



            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFilePdf').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },

        centerPopupViewFile: function () {



            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFile').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },



        reloadData: function () {

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            this.hideShowButtonsSelRow();


            window.table.ajax.reload();


            setTimeout(() => {

                this.reloadData();

            }, 10000);
        },




        btnCancelViewFileDcoCvPersMobile: function () {

            this.$modal.hide('popupViewFileDocCvPersMobile');

        },


        btnCancelViewFileDocCvPers: function () {

            this.$modal.hide('popupViewFileDocCvPers');

        },


        viewFile: async function () {

            var that = this;

            var url_cv_azi = "";
            var url_cv_pers = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    url_cv_azi = $(this).attr("url-cv-azi");
                    url_cv_pers = $(this).attr("url-cv-pers");

                }

            });


            if ((url_cv_azi != "") && (url_cv_pers != "")) {

                that.chooseViewFile = "CV_PERSONALE";

                $(".contpopupchooseviewfile").show();

                that.$modal.show('popupChooseViewFile');

            }


            if ((url_cv_azi != "") && (url_cv_pers == "")) {



                var file_path2 = "";

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {


                        file_path2 = $(this).attr("url-cv-azi");

                    }

                });

                var elements2 = file_path2.split(".");

                var isPdf2 = 0;

                if (elements2[elements2.length - 1] == "pdf") {

                    isPdf2 = 1;

                }


                if (isPdf2 == 0) {


                    that.viewFileDocDocx();


                }


                if (isPdf2 == 1) {


                    console.log("ok");


                }


            }


            if ((url_cv_azi == "") && (url_cv_pers != "")) {

                console.log("ok2");


                var file_path = "";

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {


                        file_path = $(this).attr("url-cv-pers");

                    }

                });


                var elements = file_path.split(".");

                var isPdf = 0;

                if (elements[elements.length - 1] == "pdf") {

                    isPdf = 1;

                }


                console.log("IS_PDF: ", isPdf);


                if (isPdf == 1) {


                    $(".contpopupviewfilepdf").show();

                    that.$modal.show('popupViewFilePdf');

                    that.pdfUrl = file_path;

                    setTimeout(() => {


                        that.loadPdf();

                        that.centerPopupViewFilePdf();


                    }, 200);



                }


                if (isPdf == 0) {



                    var url =
                        "https://view.officeapps.live.com/op/embed.aspx?src=" +
                        file_path;

                    $(".contpopupviewfiledoccvpers").show();

                    that.$modal.show('popupViewFileDocCvPers');

                    setTimeout(() => {

                        $("#iframe-pdf").attr("src", url);

                        that.centerPopupViewFile();



                    }, 100);




                }


            }




        },


        btnCancelChooseFile: function () {

            this.$modal.hide('popupChooseViewFile');

        },


        btnConfirmChooseFile: function () {

            var that = this;

            that.$modal.hide('popupChooseViewFile');


            if (that.chooseViewFile == "CV_PERSONALE") {

                console.log("ok3");


                var file_path = "";

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {


                        file_path = $(this).attr("url-cv-pers");

                    }

                });


                var elements = file_path.split(".");

                var isPdf = 0;

                if (elements[elements.length - 1] == "pdf") {

                    isPdf = 1;

                }


                if (isPdf == 1) {


                    $(".contpopupviewfilepdf").show();

                    that.$modal.show('popupViewFilePdf');

                    that.pdfUrl = file_path;

                    setTimeout(() => {


                        that.loadPdf();

                        that.centerPopupViewFilePdf();


                    }, 200);


                }


                if (isPdf == 0) {



                    var url =
                        "https://view.officeapps.live.com/op/embed.aspx?src=" +
                        file_path;


                    if (that.getWindowMobile() == 0) {

                        $(".contpopupviewfiledoccvpers").show();

                        that.$modal.show('popupViewFileDocCvPers');

                        setTimeout(() => {

                            $("#iframe-pdf").attr("src", url);


                        }, 100);


                    }


                    if (that.getWindowMobile() == 1) {

                        $(".contpopupviewfiledoccvpersmobile").show();

                        that.$modal.show('popupViewFileDocCvPersMobile');

                        setTimeout(() => {

                            $("#iframe-pdf").attr("src", url);


                        }, 100);


                    }





                }



            }



            if (that.chooseViewFile == "CV_AZIENDALE") {

                var file_path2 = "";

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {


                        file_path2 = $(this).attr("url-cv-azi");

                    }

                });

                var elements2 = file_path2.split(".");

                var isPdf2 = 0;

                if (elements2[elements2.length - 1] == "pdf") {

                    isPdf2 = 1;

                }


                if (isPdf2 == 0) {


                    that.viewFileDocDocx();


                }


                if (isPdf2 == 1) {


                    console.log("ok");


                }



            }




        },



        deleteTempFile: async function () {

            var that = this;


            that.$modal.hide('popupViewFile');

            var response = await apicandidate.deleteTempFile(
                that.nomeFile
            ).then((res) => {


                console.log("res from deleteTempFile", res);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        viewFileDocDocx: async function () {

            var that = this;

            var url_cv_azi = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    url_cv_azi = $(this).attr("url-cv-azi");

                }

            });



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.getContentDocDocxFile(
                url_cv_azi
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getContentDocDocxFile", res);

                that.nomeFile = res.data.NomeFile;

                var url =
                    "https://view.officeapps.live.com/op/embed.aspx?src=" +
                    res.data.UrlPath;

                $(".contpopupviewfile").show();

                that.$modal.show('popupViewFile');

                setTimeout(() => {

                    $("#iframe-pdf").attr("src", url);


                    that.centerPopupViewFile();


                }, 100);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },





        btnCancelViewFileMobile: function () {

            this.deleteTempFileMobile();

        },



        btnCancelViewFile: function () {

            this.deleteTempFile();

        },




        getWindowMobile: function () {

            if (window.innerWidth <= 768) {

                return 1;

            }
            else {

                return 0;

            }

        },


        viewPdfFile: async function () {


            var that = this;

            var file_path = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    file_path = $(this).attr("url-cv-pers");

                }

            });


            $(".contpopupviewfilepdf").show();

            that.$modal.show('popupViewFilePdf');

            that.pdfUrl = file_path;

            setTimeout(() => {


                that.loadPdf();

                that.centerPopupViewFilePdf();


            }, 200);




        },

        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('container-view-pdf');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },

        btnCancelViewFilePdf: function () {


            this.$modal.hide('popupViewFilePdf');


        },

        changeFilterGestito: async function (gestito) {

            console.log(status);

            this.sheetFilterGestito = false;

            window.curGestito = gestito;

            window.$cookies.set("ricerca_candidati_filtro_gestiti", gestito, "9y");

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },

        manageClickRegioni: async function (regione) {

            console.log("AZI SEL: ", regione);

            this.sheetRegioni = false;

            window.idRegione = regione.idregione;

            window.$cookies.set("ricerca_candidati_filtro_id_regione", regione.idregione, "9y");

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        btnDeleteSearch: function () {

            console.log("ok");

            window.curSrcText = "";

            window.curStatus = "-1";
            window.idRegione = "-1";
            window.curGestito = ""

            window.$cookies.set("ricerca_candidati_filtro_testo", "", "9y");

            window.$cookies.set("ricerca_candidati_filtro_stato", "-1", "9y");

            window.$cookies.set("ricerca_candidati_filtro_id_regione", "-1", "9y");

            window.$cookies.set("ricerca_candidati_filtro_gestiti", "", "9y");

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            this.hideShowButtons(false);


            window.table.ajax.reload();


        },

        createPdfReport: async function () {

            var that = this;

            that.$modal.hide('popupReport');



            console.log("INSERITO DAL: ", that.inseritoDal);
            console.log("INSERITO AL: ", that.inseritoAl);
            console.log("AGGIORNATO DAL: ", that.aggiornatoDal);
            console.log("AGGIORNATO AL: ", that.aggiornatoAl);
            console.log("STATO: ", that.idStato);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.createPdfReport(
                v_token,
                that.inseritoDal,
                that.inseritoAl,
                that.aggiornatoDal,
                that.aggiornatoAl,
                that.idStato
            ).then((res) => {


                that.$root.$children[0].showProgress = false;
                console.log("res from createPdfReport", res);

                var file_path = res.data.val_url;
                var a = document.createElement('A');
                a.href = file_path;
                a.target = "_blank";
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        btnConfirmReportOld: function () {


            this.$modal.hide('popupReport');


            console.log("INSERITO DAL: ", this.inseritoDal);
            console.log("INSERITO AL: ", this.inseritoAl);
            console.log("AGGIORNATO DAL: ", this.aggiornatoDal);
            console.log("AGGIORNATO AL: ", this.aggiornatoAl);
            console.log("STATO: ", this.idStato);

            var v_token = window.$cookies.get("token");


            var url_call = this.getUrlDomain("TwoRecruiSearchCandidateReportPdfApi") + "?username=" + v_token + "&inseritodal=" + this.inseritoDal + "&inseritoal=" + this.inseritoAl + "&aggiornatodal=" + this.aggiornatoDal + "&aggiornatoal=" + this.aggiornatoAl + "&stato=" + this.idStato;

            console.log("URL TO CALL: ", url_call);

            window.open(url_call, "_blank");


        },




        btnConfirmReport: function () {



            this.createPdfReport();


        },


        btnResetReport: function () {

            this.inseritoDal = null;
            this.inseritoAl = null;

            this.aggiornatoDal = null;
            this.aggiornatoAl = null;

        },


        btnCancelReport: function () {

            this.$modal.hide('popupReport');

        },

        btnCancelInfoCandidato: function () {

            this.$modal.hide('popupInfoCandidato');


        },

        sendMail: async function () {

            var that = this;

            var txt_body = tinymce.activeEditor.getContent();
            var mail_to = that.mailToAds;

            that.$modal.hide('popupMail');

            console.log("MAIL BODY: ", txt_body);
            console.log("MAIL ADDRESS TO: ", mail_to);

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("ID SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            var response = await apicandidate.sendMail(
                v_token,
                ids,
                txt_body,
                mail_to,
                that.idAdsSel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendMail", res);

                that.$swal({
                    icon: "success",
                    text: "Mail inviata correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();


                    window.table.ajax.reload();

                    that.hideShowButtons(false);


                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );





        },


        loadPreview: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("ID SEL:", ids);

            console.log("ID ADS SEL:", that.idAdsSel);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.prevMail(
                v_token,
                ids,
                that.idAdsSel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from prevMail", res);

                that.mailToAds = res.data.MailTo;


                $(".contpopupmail").show();

                that.$modal.show('popupMail');


                setTimeout(() => {

                    tinymce.init({
                        selector: '#sec_body',  // change this value according to your HTML

                        plugin: 'a_tinymce_plugin',
                        height: "370",
                        mode: "textareas",
                        entity_encoding: "raw",
                        add_unload_trigger: false,
                        remove_linebreaks: false,
                        apply_source_formatting: false,
                        force_br_newlines: true,
                        force_p_newlines: false,
                        forced_root_block: '', // Needed for 3.x

                        a_plugin_option: true,
                        a_configuration_option: 400

                    });

                    setTimeout(() => {
                        tinymce.activeEditor.setContent(res.data.TxtPrevMail);

                    }, 700);

                }, 500);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        returnToAds: async function () {

            var that = this;

            console.log("ID ADS SEL: ", that.idAdsSel);

            window.AdsSelId = that.idAdsSel;

            var v_token = window.$cookies.get("token");

            that.idAdsSel = ""; // reset id ads selezionati

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.getAds(
                v_token,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAds", res);

                that.listAds = res.data.Ads;

                setTimeout(() => {

                    $(".contpopupads").show();

                    that.$modal.show('popupAds');

                    console.log("ID ADS SEL 2: ", window.AdsSelId);

                    setTimeout(() => {

                        console.log("ID ADS SEL 3: ", window.AdsSelId);

                        // ripristino i checkbox già selezionati

                        var elements = window.AdsSelId.split(",");

                        console.log("ELEMENTS: ", elements);


                        $(".val_status_ads").each(function (index) {

                            console.log(index);

                            console.log("VAL: ", $(this).val());

                            for (var x = 0; x <= elements.length - 1; x++) {

                                if ($(this).val() == elements[x]) {

                                    console.log("ok");

                                    $(this).prop("checked", true);

                                }
                                else {

                                    console.log("no");

                                }

                            }



                        });





                    }, 500);





                }, 300);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        infoCandidato: async function () {

            var that = this;

            var ids = "";

            var v_row = null;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    v_row = $(this).parent().parent();

                }

            });



            var row_data = window.table.row(v_row).data();

            console.log("ROW DATA: ", row_data);



            that.nomeCandidato = row_data.nome_info;
            that.cognomeCandidato = row_data.cognome_info;
            that.emailCandidato = row_data.mail_info;
            that.cellulareCandidato = row_data.cellulare_info;

            that.hrefMailCandidato = "mailto:" + that.emailCandidato;

            that.hrefCellCandidato = "tel:+39" + that.cellulareCandidato;

            that.targetCellCandidato = "_system";

            setTimeout(() => {

                $(".contpopupinfocandidato").show();

                that.$modal.show('popupInfoCandidato');


            }, 100);




        },


        checkPrivacy: async function () {


            var that = this;



            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.checkPrivacy(
                ids,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkPrivacy", res);


                if (res.data.Result == "OK") {


                    console.log("ok");

                    that.sendInvite();

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },


        sendInvite: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.idAdsSel = ""; // reset id ads selezionati

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.getAds(
                v_token,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAds", res);

                that.listAds = res.data.Ads;

                setTimeout(() => {

                    $(".contpopupads").show();

                    that.$modal.show('popupAds');

                }, 300);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        sendInviteOld: async function () {

            var that = this;

            $(".contpopupads").show();

            that.$modal.show('popupAds');


        },

        previewMail: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("ID SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.prevMail(
                v_token,
                ids,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from prevMail", res);


                $(".contpopupmail").show();

                that.$modal.show('popupMail');


                setTimeout(() => {

                    tinymce.init({
                        selector: '#sec_body',  // change this value according to your HTML

                        plugin: 'a_tinymce_plugin',
                        height: "370",
                        mode: "textareas",
                        entity_encoding: "raw",
                        add_unload_trigger: false,
                        remove_linebreaks: false,
                        apply_source_formatting: false,
                        force_br_newlines: true,
                        force_p_newlines: false,
                        forced_root_block: '', // Needed for 3.x

                        a_plugin_option: true,
                        a_configuration_option: 400

                    });

                    setTimeout(() => {
                        tinymce.activeEditor.setContent(res.data.TxtPrevMail);

                    }, 700);

                }, 500);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        previewMailOld: async function () {

            var that = this;

            $(".contpopupmail").show();

            that.$modal.show('popupMail');


            setTimeout(() => {

                tinymce.init({
                    selector: '#sec_body',  // change this value according to your HTML

                    plugin: 'a_tinymce_plugin',
                    height: "370",
                    mode: "textareas",
                    entity_encoding: "raw",
                    add_unload_trigger: false,
                    remove_linebreaks: false,
                    apply_source_formatting: false,
                    force_br_newlines: true,
                    force_p_newlines: false,
                    forced_root_block: '', // Needed for 3.x

                    a_plugin_option: true,
                    a_configuration_option: 400

                });

                // setTimeout(() => {
                //     tinymce.activeEditor.setContent("prova");

                // }, 700);

            }, 500);





        },


        historyCustomer: async function () {


            var that = this;

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("ID SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.customersHistory(
                v_token,
                ids,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from customersHistory", res);

                if (res.data.Result == "OK") {


                    that.customers_history = res.data.Customers;

                    console.log("ok")

                    setTimeout(() => {

                        $(".contpopuphistory").show();

                        that.$modal.show('popupHistory');

                        console.log("ok2");


                    }, 300);



                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        initFilter: async function () {


            var that = this;

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.initFilter(
                v_token,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initFilter", res);

                that.allStatus = res.data.ChangeStatus;
                that.allFilterStatus = res.data.FilterStatus;

                window.portali = res.data.portali;
                window.regioni = res.data.regioni;
                window.contratti = res.data.contratti;
                window.studi = res.data.studi;
                window.giornatatipo = res.data.giornatatipo;
                window.province = res.data.province;
                window.comuni = res.data.comuni;

                window.dateStartFilter = res.data.DateStartForFilter;
                window.dateEndFilter = res.data.DateEndForFilter;

                that.regioni = res.data.regioniFilter;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        changeFilterStatus: async function (status) {

            console.log(status);

            this.sheetFilterStatus = false;

            window.curStatus = status;

            window.$cookies.set("ricerca_candidati_filtro_stato", status, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },


        changeStatus: async function (status) {

            console.log(status);

            this.sheetChangeStatus = false;

            var that = this;

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("VAL STATUS:", status);
            console.log("ID SEL:", ids);

            if (status != "Iter interrotto") {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;


                var response = await apicandidate.updateMultiStatus(
                    v_token,
                    ids,
                    status,
                    null
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateMultiStatus", res);

                    that.$swal({
                        icon: "success",
                        text: "Stati aggiornati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();


                        window.table.ajax.reload();


                        that.hideShowButtons(false);


                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );


            }
            else {


                that.motIterInterrotto = "";

                $(".contpopupiterinterrotto").show();

                that.$modal.show('popupIterInterrotto');


            }






        },



        hideShowButtonsSelRow: function () {

            var tot = 0;
            var tot_has_attach = 0;
            var tot_has_attach_azi = 0;
            var has_cust = "";
            var has_attach = "";
            var has_attach_azi = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    has_cust = $(this).attr("has-cust");

                    has_attach = $(this).attr("has-attach");

                    has_attach_azi = $(this).attr("has-attach-azi");

                    if (has_attach == "S") {
                        tot_has_attach = tot_has_attach + 1;
                    }

                    if (has_attach_azi == "S") {
                        tot_has_attach_azi = tot_has_attach_azi + 1;
                    }

                }


            });

            console.log("HAS_CUST: ", has_cust);

            setTimeout(() => {

                var pulsantis = [];


                if (tot == 1) {


                    pulsantis.push({
                        text: "Down CV Company",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/storico_candidato.png"),
                        link: "/info",
                        id: "btn_history",
                        disabled: false,
                        title: "Storico",
                        width: 20
                    });


                }

                if (tot == 0) {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2recruit.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        title: "Aggiungi",
                        width: 30,

                    });


                }



                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-row-2recruit.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/info_2recruit.png",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                        title: "Info",
                        width: 20
                    });

                    pulsantis.push({
                        text: "Send Invite",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/email-2recruit.png",
                        link: "/info",
                        id: "btn_send_invite",
                        disabled: false,
                        title: "Manda Invito",
                        width: 28
                    });


                    if (tot_has_attach == 1) {

                        pulsantis.push({
                            text: "Down CV Personal",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/download_cv_personale.png"),
                            link: "/info",
                            id: "btn_down_cv_pers",
                            disabled: false,
                            title: "Scarica CV Personale",
                            width: 23
                        });


                    }


                    if (tot_has_attach_azi == 1) {

                        pulsantis.push({
                            text: "Down CV Company",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/download_cv_aziendale.png"),
                            link: "/info",
                            id: "btn_down_cv_azi",
                            disabled: false,
                            title: "Scarica CV Azienda",
                            width: 23
                        });


                    }





                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Update Status",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/change-status-2recruit.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_update_status",
                        disabled: false,
                        title: "Aggiorna Stato",
                        width: 30
                    });


                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-2recruit.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 30
                    });


                    if (tot == tot_has_attach) {

                        pulsantis.push({
                            text: "Crea cv aziendale",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/document_2recruit.png",
                            link: "/info",
                            id: "btn_crea_cv_azi",
                            disabled: false,
                            title: "Crea CV Aziendale",
                            width: 20
                        });


                    }





                }



                if (tot == 1) {


                    if ((tot_has_attach == 1) || (tot_has_attach_azi == 1)) {

                        pulsantis.push({
                            text: "Down CV Personal",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/view-2recruit.png"),
                            link: "/info",
                            id: "btn_view_file",
                            disabled: false,
                            title: "Vedi CV",
                            width: 30
                        });

                    }



                }




                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;

            var filtri = `<img src='/2recruit/img/refresh-2recruit.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/2recruit/img/filtro-2recruit.png' style='border:0!important;' class='selstato' title='Stato' /><img src='/2recruit/img/pdf-2recruit.png' style='border:0!important;' class='selpdfreport' title='Crea PDF Report' /><img src='/2recruit/img/2recr_regioni.png' style='border:0!important;' class='selregioni' title='Regioni' /><img src='/2recruit/img/filtro_gestito.png' style='border:0!important;' class='selgestito' title='Gestito' />`;

            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },



                {
                    data: 'row_id',
                    orderable: true,
                    searchable: false,
                    name: '',
                    width: '0.5%',
                },

                {
                    data: 'in_carico',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },


                {
                    data: 'enable_wip',
                    orderable: false,
                    name: 'enable_wip',
                    width: '1%'
                },





                {
                    data: 'created_at',
                    orderable: true,
                    name: 'created_at',
                    width: '2%',

                    render: function (data, row) {
                        console.log(data, row);

                        var date = "";
                        try {

                            date = data.substring(0, 10);
                        } catch (error) {
                            console.log(error);
                        }

                        var hour = "";
                        try {

                            hour = data.split(" ")[1].slice(0, 5);
                        } catch (error) {
                            console.log(error);
                        }

                        var html = "<div class='cl-div-hid'>" + data + "</div><div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        return html;
                    }


                },

                {
                    data: 'updated_at',
                    orderable: true,
                    name: 'updated_at',
                    width: '1%',

                    render: function (data, row) {
                        console.log(data, row);

                        var date = "";
                        try {

                            date = data.substring(0, 10);
                        } catch (error) {
                            console.log(error);
                        }

                        var hour = "";
                        try {

                            hour = data.split(" ")[1].slice(0, 5);
                        } catch (error) {
                            console.log(error);
                        }

                        var html = "<div class='cl-div-hid'>" + data + "</div><div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        return html;
                    }


                },


                {
                    data: 'cognome',
                    orderable: true,
                    name: 'cognome',
                    width: '5%'
                },

                {
                    data: 'nome',
                    orderable: true,
                    name: 'nome',
                    width: '5%'
                },

                {
                    data: 'esperienza',
                    orderable: true,
                    name: 'esperienza',
                    width: '3%'
                },


                {
                    data: 'mail',
                    orderable: true,
                    name: 'mail',
                    width: '1%'
                },

                {
                    data: 'cellulare',
                    orderable: true,
                    name: 'cellulare',
                    width: '1%'
                },


                {
                    data: 'skill',
                    orderable: true,
                    name: 'skill',
                    width: '5%'
                },

                {
                    data: 'stato',
                    orderable: true,
                    name: 'stato',
                    width: '4%'
                },



                {
                    data: 'data_nascita',
                    orderable: true,
                    name: 'data_nascita',
                    width: '5%'
                },

                {
                    data: 'reg_residenza',
                    orderable: true,
                    name: 'reg_residenza',
                    width: '3%'
                },

                {
                    data: 'portale',
                    orderable: true,
                    name: 'portale',
                    width: '1%'
                },

                {
                    data: 'cliente',
                    orderable: true,
                    name: 'cliente',
                    width: '1%'
                },


                {
                    data: 'annuncio',
                    orderable: true,
                    name: 'annuncio',
                    width: '1%'
                },

                {
                    data: 'contratto',
                    orderable: true,
                    name: 'contratto',
                    width: '2%'
                },



                {
                    data: 'preavviso',
                    orderable: true,
                    name: 'preavviso',
                    width: '1%'
                },


                {
                    data: 'ral',
                    orderable: true,
                    name: 'ral',
                    width: '1%'
                },

                {
                    data: 'feedback',
                    orderable: true,
                    name: 'feedback',
                    width: '3%'
                },

                {
                    data: 'note',
                    orderable: true,
                    name: 'note',
                    width: '3%'
                },


                {
                    data: 'cv',
                    orderable: false,
                    name: 'cv',
                    width: '4%'
                },

                {
                    data: 'cv_azienda',
                    orderable: false,
                    name: 'cv_azienda',
                    width: '3%'
                },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,





                    ajax: {
                        url: pointerVue.getUrlDomain("TwoRecruitGetSearchCandidatesNewApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start_ = window.perpage;

                            valori.start_ = 0;
                            valori.length_ = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;
                            valori.curSrcText = window.curSrcText;

                            valori.idRegione = window.idRegione;

                            valori.curGestito = window.curGestito;

                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();



                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 700);


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 1000);

                            var valori = window.valori;
                            valori.start_ = window.start_;

                            $.ajax({
                                url: pointerVue.getUrlDomain("TwoRecruitGetSearchCandidatesNewApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    //   $(".spin").hide();
                                    window.loading = 0;
                                    window.start_ += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Ricerca Candidati</div>

                    ` + filtri + `

                </div>
                <div><img src='/2recruit/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>
            `);


                $(".selgestito").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetFilterGestito = true;


                });


                $(".selstato").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetFilterStatus = true;


                });

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });

                // $('body').on('click', '.btn-confirm-search', function () {

                //     console.log("ok");

                //     window.curSrcText = pointerVue.textSearch;

                //     window.$cookies.set("ricerca_candidati_filtro_testo", pointerVue.textSearch, "9y");

                //     pointerVue.$modal.hide('popupSearch');

                //     window.table.ajax.reload();


                // });

                // $('body').on('click', '.btn-cancel-search', function () {

                //     console.log("ok");

                //     pointerVue.$modal.hide('popupSearch');

                // });




                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_ricerca_candidati th', function () {


                    console.log("TH-ACTIVE: ", $(this));


                    $(this).removeClass("sorting");



                });

                $('body').on('click', '.cont_ricerca_candidati tr', function () {


                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            this.row_selected = row_data;


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2recruit.png",
                            //class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back.png",
                        //     link: "/confOrariChoice",
                        //     class: "inverted",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro"
                        // },

                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2recruit.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30,
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                            class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica"

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                            class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina"

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back.png",
                        //     link: "/confOrariChoice",
                        //     class: "inverted",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro"
                        // },


                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        deleteRow: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_2recruit.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_2recruit.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicandidate.deleteSearchCandidate(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteSearchCandidate", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                // window.table._fnDraw();
                                // that.hideShowButtons(false);

                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        createCVCompany: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.$swal
                .fire({
                    title: "Crea CV Aziendale",
                    html: "Volete creare / aggiornare il cv aziendale degli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_2recruit.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_2recruit.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicandidate.createCVCompany(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from createCVCompany", res);

                            that.$swal({
                                icon: "success",
                                text: "CV aziendale creato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                // window.table._fnDraw();
                                // that.hideShowButtons(false);

                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },



    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#container-view-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('/2recruit/font/fontawesome-webfont.woff2') format('woff2'),
        url('/2recruit/font/fontawesome-webfont.woff') format('woff'),
        url('/2recruit/font/fontawesome-webfont.ttf') format('truetype');
    font-style: normal;
}


.sorting_asc:after {
    content: "\f0de";
    font-family: 'FontAwesome';
    margin-left: 5px;
}

.sorting_desc:after {
    content: "\f0dd";
    font-family: 'FontAwesome';
    margin-left: 5px;
}

.sorting:after {
    content: "";
    font-family: 'FontAwesome';
}



.cont_ricerca_candidati .selgestito {
    max-width: 26px;
    min-height: 26px;
    margin-left: 18px;
    color: black !important;
    padding: 0px 0px;
    min-width: 26px !important;
    text-indent: -119px;
    background-size: 26px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.clColCvPers {
    width: 55px;
}

.clColCvAzi {
    width: 55px;
}



.clColGestito {
    width: 55px;
}

.lnkEMail {
    color: black !important;
}

.lnkPhone {
    color: black !important;
}

.cont-table-search-cand {
    overflow-x: auto !important;
    overflow-y: hidden;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #ff00a3;

}

.pay a {
    color: #ff00a3;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #ff00a3;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table-search-cand .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}



.cont_ricerca_candidati #myTable {
    margin-right: 3px;
    width: 2035px !important;
    margin-top: 69px;
}




.cont_ricerca_candidati table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_ricerca_candidati table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    /* padding-bottom: 9px !important; */
}

.cont_ricerca_candidati th {
    padding-left: 5px !important;
}

.cont_ricerca_candidati ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_ricerca_candidati .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_ricerca_candidati #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    margin-top: 13px !important;
    background-color: white;
    border-color: #ff00a3;

}

.cont_ricerca_candidati div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_ricerca_candidati #myTable_wrapper {
    min-width: initial;
    min-width: 2050px;
    max-width: 2050px;
    width: 2050px;
    font-size: 11px;
    color: black;
    font-weight: bold;
}


.cont-table-search-cand .text_title {
    background-color: white !important;
    color: black !important;
    font-weight: bold !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 0px !important;
    font-size: 10px !important;
    min-width: 88px !important
}

.cont_ricerca_candidati .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.cont_ricerca_candidati .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.cont_ricerca_candidati .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_ricerca_candidati .checkall {
    width: 13px;
    height: 13px;
}


.cont_ricerca_candidati .val_status {
    width: 10px !important;
    margin-top: 2px !important;
}


.cont_ricerca_candidati .selaggiorna {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ricerca_candidati .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_ricerca_candidati .selstato {
    max-width: 27px !important;
    min-height: 27px !important;
    margin-left: 20px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 14px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
}


.closeStatusSheet {
    position: absolute;
    right: 0;
}

/* .cont_ricerca_candidati .font-row-grid-datetime {
    font-size: 10px !important;
    width: 58px !important;
} */

.cont_ricerca_candidati .font-row-grid-datetime {
    font-size: 10px !important;
    width: 70px !important;
}


.cont_ricerca_candidati .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cl-div-hid {
    display: none;
}

.cont_ricerca_candidati input[type='search'] {
    visibility: hidden;
}

.cl-down-pdf {
    cursor: pointer;
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    content: url(https://app.emtool.eu/public/_lib/img/nm_icon_pdf.gif);
    margin-left: 12px;
}

.cl-down-doc {
    content: url(https://app.emtool.eu/public/_lib/img/Word-icon.png);
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    cursor: pointer;
    margin-left: 12px;
}

.cl-down-pttx {
    cursor: pointer;
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    content: url(https://app.emtool.eu/public/_lib/img/PowerPoint-icon.png);
    margin-left: 12px;
}

.cl-down-xlsx {
    cursor: pointer;
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    content: url(https://app.emtool.eu/public/_lib/img/Excel-icon.png);
    margin-left: 12px;
}

.cl-down-png {
    cursor: pointer;
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    content: url(https://app.emtool.eu/public/_lib/img/PNG-icon.png);
    margin-left: 12px;
}

.cont_ricerca_candidati .selpdfreport {
    max-width: 22px;
    min-height: 22px;
    margin-left: 18px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 22px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ricerca_candidati .selregioni {
    max-width: 26px;
    min-height: 26px;
    margin-left: 18px;
    color: black !important;
    padding: 0px 0px;
    min-width: 26px !important;
    text-indent: -119px;
    background-size: 26px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ricerca_candidati .cl-data-nascita {
    width: 90px !important;
}

.cont_ricerca_candidati .cl-cellulare {
    width: 83px;
}

.contpopupreport .v-input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}



.cl-pdf {
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    content: url(https://app.emtool.eu/public/_lib/img/nm_icon_pdf.gif);
    margin-left: 12px;
}

.cl-doc {
    content: url(https://app.emtool.eu/public/_lib/img/Word-icon.png);
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    margin-left: 12px;
}

.cl-pttx {
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    content: url(https://app.emtool.eu/public/_lib/img/PowerPoint-icon.png);
    margin-left: 12px;
}

.cl-xlsx {
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    content: url(https://app.emtool.eu/public/_lib/img/Excel-icon.png);
    margin-left: 12px;
}

.cl-png {
    cursor: pointer;
    transform: translateY(4px);
    min-height: 20px;
    max-width: 20px;
    height: 23px;
    display: inline-block;
    content: url(https://app.emtool.eu/public/_lib/img/PNG-icon.png);
    margin-left: 12px;
}



@media screen and (max-width: 960px) {

    .cont_ricerca_candidati .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_ricerca_candidati #myTable {
        margin-top: 57px;

    }

    .cont_ricerca_candidati #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_ricerca_candidati .cl-cellulare {
        display: inline;
    }

    .cont_ricerca_candidati .cl-data-nascita {
        display: inline;
    }



}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_ricerca_candidati #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_ricerca_candidati #myTable_filter {
        top: 44px;
        position: fixed;

    }

    .cont_ricerca_candidati .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_ricerca_candidati #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
        margin-top: 14px !important;
    }

    .cont_ricerca_candidati .cl_num {
        margin-left: -2px !important;
    }


    .cont_ricerca_candidati .cl_checkall {
        padding-left: 20px;
        padding-top: 2px;
    }

    .font-row-grid-datetime {
        font-size: 10px !important;
        width: 100px !important;
        display: inline-table !important;
    }

    .cl-txt-minor {
        display: inline;
    }

    canvas {
        border: 1px solid #c7c4a9;
        margin-top: 10px;
        margin-left: 573px;
    }

}
</style>
